@import url('https://fonts.googleapis.com/css?family=Montserrat');
* {
  margin: 0;
  box-sizing: border-box;
}

:root {
  --admin-font: 'Courier New', Courier, monospace;
  --category-font: 'GT America Bold', sans-serif;
  --body-font: 'GT America Mono', monospace;
  --ui-font: 'GT America', sans-serif;
  --input-border: #aaa;
}

.landingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 0;
  background-color: aliceblue;
}
.loginContainer .loginForm {
  display: flex;
  flex-direction: column;
  min-width: 256px;
}

.loginForm input {
  margin-top: 12px;
  height: 32px;
  border-style: none;
  border: 1px var(--input-border) solid;
  border-radius: 4px;
}

/*# sourceMappingURL=index.css.map */

.no-highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.icon-wrapper.logoutButton {
  margin-left: 24px;
}

.logoutButton span:hover {
  cursor: pointer;
}

#App.no-padding {
  padding: 0;
}

/*Gallery*/

.gallery-content .grid-image {
  height: auto;
  width: 100%;
  display: flex;
  border: 1px solid #333;
  border-radius: 4px;
  margin-bottom: 8px;
}

.gallery-content figure {
  margin: 0;
}

.gallery-content .grid-image img {
  width: 20%;
}

.gallery-image {
  padding: 0;
}

.gallery .gallery-image img {
  border-radius: 0;
  min-height: 96px;
}

.gallery-content .grid-image img:hover {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.gallery-content .grid-image figcaption {
  margin-left: 24px;
  align-items: center;
  font-family: var(--admin-font);
  font-size: 16px;
  font-weight: bold;
}

.gallery-content .editDelete {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 25%;
}

.editDelete button {
  min-width: 64px;
  margin-right: 24px;
  border-style: none;
  background: none;
  font-family: var(--admin-font);
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  /* width: 50%; */
}

/* button.edit {
  color: red;
} */

button.delete {
  color: red;
}

button:hover {
  cursor: pointer;
}

.item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.add-photo,
.add-gallery,
.add-post {
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  font-family: var(--admin-font);
  font-size: 16px;
  font-weight: bold;
}

.hover-cursor {
  cursor: pointer;
}

.add-photo form,
.add-post form,
.add-gallery form {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.add-photo form input,
.add-post form input,
.add-gallery form input {
  margin-bottom: 12px;
  height: 32px;
  border-style: none;
  border: 1px var(--input-border) solid;
  border-radius: 4px;
  font-family: var(--admin-font);
}
.add-photo input:first-of-type,
.add-post form > input:first-of-type,
.add-gallery form input:first-of-type {
  margin-top: 12px;
}

form input[type='file'] {
  border: none;
}

.cancel-submit {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.cancel-submit input {
  margin-top: 12px;
  margin-left: 12px;
  border-style: none;
  background: none;
  font-family: var(--admin-font);
  font-size: 16px;
  font-weight: bold;
}

.cancel-submit input:hover {
  cursor: pointer;
}

input.submit-button {
  color: white;
  background-color: #333;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.spinner-wrapper {
  height: 100%;
  width: 100%;
}

.breadcrumb__category {
  color: #333;
  text-decoration: none;
}

.spinner-overlay .spinner-wrapper .spinner {
  height: 64px;
  width: 64px;
  cursor: progress;
  background: none;
  box-shadow: none;
}

.breadcrumb {
  min-height: 40px;
  font-size: 24px;
  font-weight: bold;
  margin-right: 8px;
}

.breadcrumb .cancel-submit {
  display: flex;
}

.breadcrumb .cancel-submit input {
  margin-top: 0;
  border-radius: 4px;
  border: black solid 1px;
}

.breadcrumb span {
  height: 32px;
  text-wrap: nowrap;
  margin-right: 8px;
}

.edit-gallery-name {
  height: 32px;
  font-size: 22px;
  max-width: 220px;
}

.edit-icon,
span .edit-icon {
  height: 16px;
  border-radius: 0;
}

.edit-icon:hover {
  cursor: pointer;
}

.breadcrumb__edit-icon {
  border-radius: 0;
}

.gallery-content .edit-photo-form .gridImage {
  width: 25%;
  height: auto;
  max-height: 300px;
}

.edit-photo-form form {
  margin: 0;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-photo-form form input {
  width: 80%;
  margin-bottom: 12px;
  height: 32px;
  border-style: none;
  border: 1px var(--input-border) solid;
  border-radius: 4px;
  font-family: var(--admin-font);
}
.edit-photo-form form label:first-of-type {
  margin-top: 12px;
}

form label {
  width: 80%;
  font-family: var(--body-font);
  font-size: 14px;
  font-weight: 300;
  text-transform: lowercase;
}

.edit-photo-form .cancel-submit {
  margin: 0;
  width: 25%;
  align-items: center;
}
.edit-photo-form .cancel-submit input {
  margin: 0 24 0 0;
  height: 36px;
  border-radius: 4px;
  margin-right: 24px;
}

.add-post {
  border: 1px #333 solid;
  border-radius: 4px;
  font-family: var(--admin-font);
  margin-bottom: 24px;
}

.add-post form {
  width: 75%;
  align-items: flex-start;
}

.add-post form input[type='text'] {
  width: 100%;
}
.add-post form input[type='date'] {
  width: 300px;
}
.add-post form .quill {
  width: 100%;
  margin-bottom: 12px;
}

.add-post form .ql-editor {
  min-height: 200px;
}

.ql-editor {
  font-family: var(--admin-font);
  font-weight: 400;
  font-size: 14px;
}

.ql-toolbar {
  border-radius: 4px 4px 0 0;
  border: #aaa;
}

.ql-container.ql-snow {
  border: #aaa;
  border-radius: 0 0 4px 4px;
}

form input[type='text'] {
  font-size: 14px;
}

.add-post .add-post-photo {
  padding: 8px;
  color: #aaa;
  width: 100%;
  border: 1px #aaa solid;
  border-radius: 4px;
  margin-top: 44px;
}

.add-post-photo h3 {
  font-size: 14px;
}

.add-post .post-images {
  width: 100%;
  margin-top: 48px;
}

.gallery-grid-item {
  width: 100%;
  margin-top: 2px;
}

.gallery-grid-item:last-child {
  margin-bottom: 100px;
}

.grid-item__search__gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  text-transform: uppercase;
}

.client-list .ql-container {
  height: auto;
}

.profile-pic {
  cursor: pointer;
}

.profile-pic-form {
  display: flex;
  flex-direction: column;
}
.profile-pic-form .cancel-submit input {
  height: 32px;
  border-radius: 4px;
}
.profile-pic-form .cancel-submit {
  height: 100px;
  display: flex;
  align-items: flex-end;
}

.blog-edit-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog-edit-buttons button {
  font-weight: bold;
  font-family: var(--admin-font);
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  width: 120px;
  margin-bottom: 12px;
  /* border-style: none; */
  background: none;
}

/* Google sign-in */

.google-button {
  width: 100%;
}

.google-button span {
  width: 100%;
  text-align: center;
}

.post-image-edit-icons {
  display: flex;
}

.add-post-photos .grid-image .trash,
.add-post-photos .grid-image .edit-icon {
  width: 16px;
  height: auto;
  background-color: #fff;
  border-radius: 0;
  margin-right: 12px;
}
.add-post-photos .grid-image .trash:hover,
.add-post-photos .grid-image .edit-icon:hover {
  cursor: pointer;
}

.editDate {
  display: flex;
}

.hideDate {
  display: flex;
  align-items: center;
  margin-left: 24px;
}

.hideDate input {
  margin-right: 8px;
}
.hideDate span {
  font-family: var(--admin-font);
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 12px;
}

.grid-image {
  background-color: #fff;
}

.editGalleryThumb {
  width: 24%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.editGalleryThumb .cancel-submit {
  flex-direction: column;
  align-items: center;
}

.chooseGalleryThumb {
  width: 100%;
  margin: 12px 0 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editGalleryThumb button,
.editGalleryThumb select,
.editGalleryThumb input {
  width: 180px;
  margin: 0 12px 12px 12px;
  height: 32px;
  border-radius: 4px;
}

.editGalleryThumb select {
  text-align-last: center;
}

.editGalleryThumb .or {
  margin-bottom: 12px;
}
.editGalleryThumb .cancel-submit .cancel-button {
  border: 1px solid #666;
  background-color: #fff;
}
.editGalleryThumb .cancel-submit .submit-button {
  border: 1px solid #666;
}

.galleryThumb {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  width: 24%;
}

.gallery-content .grid-image .galleryThumb img {
  width: 100%;
}

.changeThumbButton {
  position: relative;
  bottom: 37px;
  padding: 8px;
  line-height: 15px;
  width: calc(100% + 1px);
  border: none;
  border-radius: 0 0 0 2px;
  background-color: rgba(255, 255, 255, 0.72);
}

.copy-photo-form .copy-button {
  color: white;
  background-color: #333;
  border: 1px solid #666;
  border-radius: 4px;
}

.copy-photo-form .cancel-button,
.copy-photo-form .copy-button {
  height: 32px;
  margin: 0 24px 0 0;
}

.copy-photo-form .cancel-submit {
  display: flex;
  align-items: center;
  width: 25%;
}

.copy-photo-form {
  width: 100%;
}

.select-gallery {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#select-copy-gallery {
  margin-left: 8px;
}

.postPhotoEdit .cancel-submit input {
  margin-top: 0;
}

.hide-submit-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.hidePost {
  display: flex;
  width: 100%;
  align-items: center;
}

.hideGallery {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 128px;
}

.hidePost span,
.hideGallery span {
  margin-left: 8px;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 12px;
}

.add-post .hidePost span,
.hideGallery span {
  margin-bottom: 0;
}

.add-post .hidePost input[type='checkbox'] {
  margin: 0;
}
.galleryContentHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.instructions h1 {
  margin-bottom: 1em;
}

.instructions {
  margin-bottom: 1em;
}

.add-post-photos .grid-image img:hover {
  cursor: move;
}

.edit-photo-form .cancel-submit {
  flex-direction: column;
  justify-content: center;
}
