@import "https://fonts.googleapis.com/css?family=Montserrat";
* {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --admin-font: "Courier New", Courier, monospace;
  --category-font: "GT America Bold", sans-serif;
  --body-font: "GT America Mono", monospace;
  --ui-font: "GT America", sans-serif;
  --input-border: #aaa;
}

.landingContainer {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.loginContainer {
  background-color: #f0f8ff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 48px 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.loginContainer .loginForm {
  flex-direction: column;
  min-width: 256px;
  display: flex;
}

.loginForm input {
  border-style: none;
  border: 1px var(--input-border) solid;
  border-radius: 4px;
  height: 32px;
  margin-top: 12px;
}

.no-highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.icon-wrapper.logoutButton {
  margin-left: 24px;
}

.logoutButton span:hover {
  cursor: pointer;
}

#App.no-padding {
  padding: 0;
}

.gallery-content .grid-image {
  border: 1px solid #333;
  border-radius: 4px;
  width: 100%;
  height: auto;
  margin-bottom: 8px;
  display: flex;
}

.gallery-content figure {
  margin: 0;
}

.gallery-content .grid-image img {
  width: 20%;
}

.gallery-image {
  padding: 0;
}

.gallery .gallery-image img {
  border-radius: 0;
  min-height: 96px;
}

.gallery-content .grid-image img:hover {
  transform: translateY(0);
}

.gallery-content .grid-image figcaption {
  font-family: var(--admin-font);
  align-items: center;
  margin-left: 24px;
  font-size: 16px;
  font-weight: bold;
}

.gallery-content .editDelete {
  justify-content: flex-end;
  align-items: center;
  width: 25%;
  display: flex;
}

.editDelete button {
  font-family: var(--admin-font);
  text-align: left;
  background: none;
  border-style: none;
  min-width: 64px;
  margin-right: 24px;
  font-size: 16px;
  font-weight: bold;
}

button.delete {
  color: red;
}

button:hover {
  cursor: pointer;
}

.item {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.add-photo, .add-gallery, .add-post {
  font-family: var(--admin-font);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100px;
  padding: 24px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.hover-cursor {
  cursor: pointer;
}

.add-photo form, .add-post form, .add-gallery form {
  flex-direction: column;
  min-width: 300px;
  display: flex;
}

.add-photo form input, .add-post form input, .add-gallery form input {
  border-style: none;
  border: 1px var(--input-border) solid;
  font-family: var(--admin-font);
  border-radius: 4px;
  height: 32px;
  margin-bottom: 12px;
}

.add-photo input:first-of-type, .add-post form > input:first-of-type, .add-gallery form input:first-of-type {
  margin-top: 12px;
}

form input[type="file"] {
  border: none;
}

.cancel-submit {
  justify-content: flex-end;
  width: 100%;
  display: flex;
}

.cancel-submit input {
  font-family: var(--admin-font);
  background: none;
  border-style: none;
  margin-top: 12px;
  margin-left: 12px;
  font-size: 16px;
  font-weight: bold;
}

.cancel-submit input:hover {
  cursor: pointer;
}

input.submit-button {
  color: #fff;
  background-color: #333;
}

.spinner-overlay {
  z-index: 100;
  background-color: #fffc;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.spinner-wrapper {
  width: 100%;
  height: 100%;
}

.breadcrumb__category {
  color: #333;
  text-decoration: none;
}

.spinner-overlay .spinner-wrapper .spinner {
  cursor: progress;
  box-shadow: none;
  background: none;
  width: 64px;
  height: 64px;
}

.breadcrumb {
  min-height: 40px;
  margin-right: 8px;
  font-size: 24px;
  font-weight: bold;
}

.breadcrumb .cancel-submit {
  display: flex;
}

.breadcrumb .cancel-submit input {
  border: 1px solid #000;
  border-radius: 4px;
  margin-top: 0;
}

.breadcrumb span {
  text-wrap: nowrap;
  height: 32px;
  margin-right: 8px;
}

.edit-gallery-name {
  max-width: 220px;
  height: 32px;
  font-size: 22px;
}

.edit-icon, span .edit-icon {
  border-radius: 0;
  height: 16px;
}

.edit-icon:hover {
  cursor: pointer;
}

.breadcrumb__edit-icon {
  border-radius: 0;
}

.gallery-content .edit-photo-form .gridImage {
  width: 25%;
  height: auto;
  max-height: 300px;
}

.edit-photo-form form {
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin: 0;
  display: flex;
}

.edit-photo-form form input {
  border-style: none;
  border: 1px var(--input-border) solid;
  font-family: var(--admin-font);
  border-radius: 4px;
  width: 80%;
  height: 32px;
  margin-bottom: 12px;
}

.edit-photo-form form label:first-of-type {
  margin-top: 12px;
}

form label {
  font-family: var(--body-font);
  text-transform: lowercase;
  width: 80%;
  font-size: 14px;
  font-weight: 300;
}

.edit-photo-form .cancel-submit {
  align-items: center;
  width: 25%;
  margin: 0;
}

.edit-photo-form .cancel-submit input {
  border-radius: 4px;
  height: 36px;
  margin: 0 24px 0 0;
}

.add-post {
  font-family: var(--admin-font);
  border: 1px solid #333;
  border-radius: 4px;
  margin-bottom: 24px;
}

.add-post form {
  align-items: flex-start;
  width: 75%;
}

.add-post form input[type="text"] {
  width: 100%;
}

.add-post form input[type="date"] {
  width: 300px;
}

.add-post form .quill {
  width: 100%;
  margin-bottom: 12px;
}

.add-post form .ql-editor {
  min-height: 200px;
}

.ql-editor {
  font-family: var(--admin-font);
  font-size: 14px;
  font-weight: 400;
}

.ql-toolbar {
  border: #aaa;
  border-radius: 4px 4px 0 0;
}

.ql-container.ql-snow {
  border: #aaa;
  border-radius: 0 0 4px 4px;
}

form input[type="text"] {
  font-size: 14px;
}

.add-post .add-post-photo {
  color: #aaa;
  border: 1px solid #aaa;
  border-radius: 4px;
  width: 100%;
  margin-top: 44px;
  padding: 8px;
}

.add-post-photo h3 {
  font-size: 14px;
}

.add-post .post-images {
  width: 100%;
  margin-top: 48px;
}

.gallery-grid-item {
  width: 100%;
  margin-top: 2px;
}

.gallery-grid-item:last-child {
  margin-bottom: 100px;
}

.grid-item__search__gallery {
  text-transform: uppercase;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  display: flex;
}

.client-list .ql-container {
  height: auto;
}

.profile-pic {
  cursor: pointer;
}

.profile-pic-form {
  flex-direction: column;
  display: flex;
}

.profile-pic-form .cancel-submit input {
  border-radius: 4px;
  height: 32px;
}

.profile-pic-form .cancel-submit {
  align-items: flex-end;
  height: 100px;
  display: flex;
}

.blog-edit-buttons {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.blog-edit-buttons button {
  font-weight: bold;
  font-family: var(--admin-font);
  background: none;
  border: 1px solid #aaa;
  border-radius: 4px;
  width: 120px;
  margin-bottom: 12px;
  font-size: 16px;
}

.google-button {
  width: 100%;
}

.google-button span {
  text-align: center;
  width: 100%;
}

.post-image-edit-icons {
  display: flex;
}

.add-post-photos .grid-image .trash, .add-post-photos .grid-image .edit-icon {
  background-color: #fff;
  border-radius: 0;
  width: 16px;
  height: auto;
  margin-right: 12px;
}

.add-post-photos .grid-image .trash:hover, .add-post-photos .grid-image .edit-icon:hover {
  cursor: pointer;
}

.editDate {
  display: flex;
}

.hideDate {
  align-items: center;
  margin-left: 24px;
  display: flex;
}

.hideDate input {
  margin-right: 8px;
}

.hideDate span {
  font-family: var(--admin-font);
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
}

.grid-image {
  background-color: #fff;
}

.editGalleryThumb {
  flex-direction: column;
  justify-content: space-between;
  width: 24%;
  min-height: 300px;
  display: flex;
}

.editGalleryThumb .cancel-submit {
  flex-direction: column;
  align-items: center;
}

.chooseGalleryThumb {
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 12px 0 24px;
  display: flex;
}

.editGalleryThumb button, .editGalleryThumb select, .editGalleryThumb input {
  border-radius: 4px;
  width: 180px;
  height: 32px;
  margin: 0 12px 12px;
}

.editGalleryThumb select {
  text-align-last: center;
}

.editGalleryThumb .or {
  margin-bottom: 12px;
}

.editGalleryThumb .cancel-submit .cancel-button {
  background-color: #fff;
  border: 1px solid #666;
}

.editGalleryThumb .cancel-submit .submit-button {
  border: 1px solid #666;
}

.galleryThumb {
  width: 24%;
}

.gallery-content .grid-image .galleryThumb img {
  width: 100%;
}

.changeThumbButton {
  background-color: #ffffffb8;
  border: none;
  border-radius: 0 0 0 2px;
  width: calc(100% + 1px);
  padding: 8px;
  line-height: 15px;
  position: relative;
  bottom: 37px;
}

.copy-photo-form .copy-button {
  color: #fff;
  background-color: #333;
  border: 1px solid #666;
  border-radius: 4px;
}

.copy-photo-form .cancel-button, .copy-photo-form .copy-button {
  height: 32px;
  margin: 0 24px 0 0;
}

.copy-photo-form .cancel-submit {
  align-items: center;
  width: 25%;
  display: flex;
}

.copy-photo-form {
  width: 100%;
}

.select-gallery {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

#select-copy-gallery {
  margin-left: 8px;
}

.postPhotoEdit .cancel-submit input {
  margin-top: 0;
}

.hide-submit-container, .hidePost {
  align-items: center;
  width: 100%;
  display: flex;
}

.hideGallery {
  justify-content: flex-end;
  align-items: center;
  min-width: 128px;
  display: flex;
}

.hidePost span, .hideGallery span {
  margin-bottom: 12px;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
}

.add-post .hidePost span, .hideGallery span {
  margin-bottom: 0;
}

.add-post .hidePost input[type="checkbox"] {
  margin: 0;
}

.galleryContentHeader {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.instructions h1, .instructions {
  margin-bottom: 1em;
}

.add-post-photos .grid-image img:hover {
  cursor: move;
}

.edit-photo-form .cancel-submit {
  flex-direction: column;
  justify-content: center;
}
/*# sourceMappingURL=index.a71d7ad5.css.map */
